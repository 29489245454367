<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-left")
        question-components-header

        div(v-for="item in list")
          div(@click="onDetail(item)" class="end-question")
            div(class="end-question-title") {{ item.data.title }}
            div(class="end-question-time") {{ item.data.end_time | moment("YYYY/MM/DD HH:mm") }}
            div(class="end-question-total") {{ item.count.reduce((a, c) => a + c) }}

      div(class="content-lright")
        pr-banner(v-if="!ui.detail")
        div(v-if="ui.detail")
          div(class="right-content-border")
            button(@click="onClose()" class="close-button") <i class="fas fa-times"></i>
            h5 {{ itemNow.data.title }}({{ itemNow.data.end_time | moment("YYYY/MM/DD HH:mm") }})
            div(v-for="(item, index) in itemNow.data.options")
              div(class="end-question-right-item")
                div {{index + 1}} {{ item }}
                div {{ itemNow.count[index] }}票

    component-footer-index
</template>

<style src="./History.scss" lang="scss" scoped>
</style>

<script>
import QuestionComponentsHeader from "./components/Header";
import { getQuestionListS3 } from '@/api/question';

export default {
  name: "QuestionHistory",
  components: {
    QuestionComponentsHeader
  },
  data() {
    return {
      ui: {
        detail: false,
      },
      itemNow: undefined,
      list: undefined,
    }
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {
    await this.init();
  },
  updated() {},
  destroyed() {},
  methods: {
    async init() {
      const ret1 = await getQuestionListS3();
      if (ret1.data.data[0]) {
        this.list = ret1.data.data.map(v => {
          let obj = {
            data: {},
            count: []
          };
          obj.data.question_id = v.id;
          obj.data.title = v.title;
          obj.data.options = v.options;
          obj.data.end_time = this.$moment(v.end_time).format("YYYY-MM-DDTHH:mm");
          const countRet = [v.result.option[0],
          v.result.option[1],
          v.result.option[2],
          v.result.option[3],
          v.result.option[4]
          ]
          obj.count = countRet.map(v => v ? v : 0);
          return obj;
        })
      } else {
        window.alert('終了済みアンケートはありません。');
        this.$router.push({ name: 'QuestionIndex' });
      }
    },
    onDetail(item) {
      this.itemNow = item;
      this.ui.detail = true;
    },
    onClose() {
      this.ui.detail = false;
    }
  }
};
</script>
